import Constants from 'constants/index';
import { isApiError } from 'util/request';
import { getNameFromLanguage } from 'util/language';
import { useDeviceInfo } from 'util/device';
import { isComplianceDocumentPath, isLeadershipDocumentPath } from 'util/url';
import { memo, useCallback, useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/browser';
import { formatBugsnagErrorMessage } from 'bugsnag';
import Container from 'react-bootstrap/Container';
import {
  useGetCompliancePlanUrlQuery,
  useGetDocumentQuery,
} from 'services/pathwayApi';
import { useGetBrowseStructureQuery } from 'services/xpApi';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import styled from 'styled-components';
import DocumentHead from 'DocumentHead';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarCollapsed } from 'store/sideBar/slice';
import { incrementHistoryStackCount, setHeader } from 'store/header/slice';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  selectUserLanguage,
  selectUserCountry,
  selectUserId,
} from 'store/user/selectors';
import useTrainingPlanTimer from 'hooks/useTrainingPlanTimer';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import {
  Card,
  CardContent,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import GenericError from 'sharedComponents/app/GenericError';
import useDocumentCookieRefresh from 'hooks/useDocumentCookieRefresh';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import { useIsNative } from 'hooks/useIsNative';
import PageNotFound404 from 'containers/404/404';
import useComplianceTimer from 'hooks/useComplianceTimer';
import parse from 'html-react-parser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  documentIsPrinting,
  documentIsTranslating,
  documentUrl,
  isTridionDocument,
} from 'store/document/selectors';
import {
  setIsPrinting,
  setIsTranslating,
  setIsTridion,
} from 'store/document/slice';
import styles from '../../styles/document.module.css';
import { clearLocalStorage } from '../Layout/Navbar/NavbarDesktop';
import TableOfContents from './TableOfContents';
import DocumentTitle from './DocumentTitle';

const Document = ({
  id,
  planId,
  stepId,
  userIds,
  stepStatus,
  isViewingFromTrainingPlan,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const [errorMsg, setErrorMessage] = useState(null);
  const [iframeLoads, setIframeLoads] = useState(0);
  const { documentId, isCompliance } = useParams();
  const location = useLocation();
  const isNotComplianceDocument = !isCompliance;
  const hasNoDocumentId = !documentId;
  const userLanguage = useSelector(selectUserLanguage);
  const { xpApi: xpApiFeatureFlag } = useFlags();
  const userCountry = useSelector(selectUserCountry);
  const {
    data: complianceUrl = {},
    isFetching: isFetchingComplianceUrl,
    error: complianceUrlError,
  } = useGetCompliancePlanUrlQuery(documentId, {
    refetchOnMountOrArgChange: true,
    skip: hasNoDocumentId || isNotComplianceDocument || xpApiFeatureFlag,
  });
  const { isDesktop: isDesktopWidth } = useDeviceInfo();
  const isNative = useIsNative();
  const { data: document_detail = {}, error } = useGetDocumentQuery(
    id ? id : documentId,
    {
      refetchOnMountOrArgChange: true,
      skip: isCompliance || xpApiFeatureFlag,
    },
  );
  const {
    data: xpBrowseData,
    isFetching: xpBrowseIsFetching,
    isSuccess: xpBrowseIsSuccess,
  } = useGetBrowseStructureQuery(
    { country: userCountry?.id, language: userLanguage },
    { skip: !xpApiFeatureFlag || !userCountry?.id || !userLanguage },
  );
  useDocumentCookieRefresh(isCompliance);
  // we always refetch document query to make sure we get a valid auth cookie
  // We shouldnt display any docs when we know the url is missing either
  // the versionId or fileId

  Document.propTypes = {
    id: PropTypes.string,
    planId: PropTypes.string,
    stepId: PropTypes.string,
    userIds: PropTypes.arrayOf(PropTypes.string),
    stepStatus: PropTypes.string,
    isViewingFromTrainingPlan: PropTypes.bool,
  };

  Document.defaultProps = {
    id: '',
    planId: '',
    stepId: '',
    userIds: [],
    stepStatus: '',
    isViewingFromTrainingPlan: false,
  };
  const versionId =
    getNameFromLanguage(document_detail?.references)?.versionId ??
    getNameFromLanguage(document_detail?.references)?.id;
  const fileId =
    getNameFromLanguage(document_detail?.references)?.fileId ??
    document_detail?.id;
  const gameUrl = getNameFromLanguage(document_detail?.references)?.reference;
  const documentName = getNameFromLanguage(document_detail?.references)?.name;
  const documentType = document_detail?.type;
  const dispatch = useDispatch();
  const fullViewDocument = includes(
    Constants.EXPANDED_FILE_FORMAT_TYPES,
    documentType,
  );
  const userId = useSelector(selectUserId);
  const [unauthorizedError, setUnauthorizedError] = useState(false);
  const xpBrowseDataDocuments = xpBrowseData?.documents;
  const getDocumentType = document =>
    xpBrowseDataDocuments?.find(doc => doc.id === document)?.sourceSystem;
  const getIcon = document =>
    xpBrowseDataDocuments?.find(doc => doc.id === document)?.icon;
  const getXylemeHtmlUrl = document =>
    xpBrowseDataDocuments?.find(doc => doc.id === document)?.contentApiUrl;
  const getTridionHtmlUrl = document =>
    xpBrowseDataDocuments?.find(doc => doc.id === document)?.contentUrl;
  const getTranslatedUrl = document =>
    xpBrowseDataDocuments?.find(doc => doc.id === document)?.otherContentUrl;
  const [tridionDocument, setTridionDocument] = useState({
    html: '',
    printUrl: '',
    translatedUrl: '',
    type: getDocumentType(documentId),
    useTranslatedUrl: false,
  });
  const [docUrlAfterRefresh, setDocUrlAfterRefresh] = useState('');
  const [fetchTridionDocument, setFetchTridionDocument] = useState(false);
  const isPrinting = useSelector(documentIsPrinting);
  const isTranslating = useSelector(documentIsTranslating);
  const documentUrlFromSearch = useSelector(documentUrl);
  const isLeadershipDocument = isLeadershipDocumentPath(location);
  const isComplianceDocument = isComplianceDocumentPath(location);
  const isTridion =
    useSelector(isTridionDocument) ||
    getDocumentType(documentId) ===
      Constants.DOCUMENT_TYPES.TRIDION.toUpperCase();
  const isMobile = isNative || !isDesktopWidth;
  const isFetching = xpApiFeatureFlag
    ? xpBrowseIsFetching
    : isFetchingComplianceUrl;
  const shouldUseIframe =
    !errorMsg && (isLeadershipDocument || !isTridion || !xpApiFeatureFlag);
  const translatedHtml = documentUrlFromSearch
    ? documentUrlFromSearch.translated
    : getTranslatedUrl(documentId);
  const calculateIframeHeight = () => {
    if (isMobile) {
      //is mobile
      if (isLeadershipDocument || isComplianceDocument) {
        return `calc(100vh - ${Constants.HEIGHT.MOBILE_TOP_NAV})`;
      } else {
        return `calc(100vh - ${Constants.HEIGHT.MOBILE_TOP_NAV} - ${Constants.HEIGHT.SEARCHBAR_SUBHEADER_HEIGHT})`;
      }
    } else {
      //is desktop
      if (isLeadershipDocument) {
        return `calc(100vh - ${Constants.HEIGHT.DESKTOP_HEADER})`;
      } else if (isComplianceDocument) {
        return '100vh';
      } else {
        return `calc(100vh - ${Constants.HEIGHT.SEARCHBAR_SUBHEADER_HEIGHT})`;
      }
    }
  };

  useEffect(() => {
    if (isLeadershipDocument) {
      dispatch(setHeader(t('Leadership.ascendOnDemand')));
    }
    return () => {
      dispatch(setHeader(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error && error.status === 403 && !isCompliance) {
      setUnauthorizedError(true);
    }
  }, [error, isCompliance]);

  const onMessageReceivedFromContent = useCallback(eventMessage => {
    if (eventMessage.origin === Constants.PATHWAY_API_URL) {
      // This is a message sent to indicate a successful page load
      if (eventMessage.data === 'ok') {
        return;
      }

      if (eventMessage.data.type === 'Error') {
        setErrorMessage(eventMessage.data.code);
      }
    }
  }, []);

  const onIframeLoad = () => {
    const updatedIframeLoads = iframeLoads + 1;
    setIframeLoads(updatedIframeLoads);
    if (updatedIframeLoads > 1) {
      dispatch(incrementHistoryStackCount());
    }
  };

  useEffect(() => {
    if (fullViewDocument) {
      dispatch(setSidebarCollapsed());
    }
  }, [dispatch, fullViewDocument]);

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromContent);

    return () => {
      window.removeEventListener('message', onMessageReceivedFromContent);
    };
  }, [onMessageReceivedFromContent]);

  // auto log time viewing doc to api if from training plan
  const timerUserIds = userIds && userIds?.length > 0 ? userIds : userId;
  const [startTrainingTimer, stopTrainingTimer] = useTrainingPlanTimer(
    planId,
    stepId,
    timerUserIds,
    stepStatus,
  );

  const [startComplianceTimer, stopComplianceTimer] = useComplianceTimer(
    documentId, //in this case documentId === pathwayCourseId
    timerUserIds,
  );

  // Catch iFrame scroll events and forward them to the native app
  window.updateScrollPosition = top => {
    const scrollEvent = new MessageEvent('iFrameScroll', {
      data: JSON.stringify(top),
    });
    window.dispatchEvent(scrollEvent);
  };

  useEffect(() => {
    if (isViewingFromTrainingPlan || Constants.IS_IN_CYPRESS_TEST) {
      startTrainingTimer();
    }
    if (isCompliance) {
      startComplianceTimer();
    }
    return () => {
      if (isViewingFromTrainingPlan || Constants.IS_IN_CYPRESS_TEST) {
        stopTrainingTimer();
      }
      if (isCompliance) {
        stopComplianceTimer();
      }
    };
  }, [
    isCompliance,
    isViewingFromTrainingPlan,
    startComplianceTimer,
    startTrainingTimer,
    stopComplianceTimer,
    stopTrainingTimer,
  ]);
  const isTridionDocumentFromSearch =
    isTridion && documentUrlFromSearch?.base?.length > 0;

  const documentUrlFromLocalStorage = JSON.parse(
    localStorage.getItem('documentUrl'),
  );

  const userRefreshesPageAfterComingFromSearch =
    shouldUseIframe && documentUrlFromLocalStorage?.base?.length;

  const isTridionDoc =
    getDocumentType(documentId) ===
      Constants.DOCUMENT_TYPES.TRIDION.toUpperCase() ||
    isTridionDocumentFromSearch ||
    fetchTridionDocument;

  const okayToFetchTridionDocs =
    (documentId && xpBrowseIsSuccess) ||
    isTridionDocumentFromSearch ||
    fetchTridionDocument;

  /** This is for when a user clicks on a Tridion document from the search results
   * and then refreshes the page.  We want to ensure we still fetch properly
   */
  useEffect(() => {
    if (userRefreshesPageAfterComingFromSearch) {
      setDocUrlAfterRefresh(documentUrlFromLocalStorage?.base);
      if (localStorage.getItem('isTridion') === 'true') {
        setFetchTridionDocument(true);
      }
    }
  }, [
    documentUrlFromLocalStorage?.base,
    userRefreshesPageAfterComingFromSearch,
  ]);

  // This fetches the Tridion html from the content api
  useEffect(() => {
    const fetchData = async () => {
      if (okayToFetchTridionDocs) {
        let htmlUrl;
        if (fetchTridionDocument) {
          htmlUrl = docUrlAfterRefresh;
        } else if (tridionDocument?.useTranslatedUrl) {
          htmlUrl = documentUrlFromSearch?.translated;
        } else if (isTridionDocumentFromSearch) {
          htmlUrl = documentUrlFromSearch?.base;
        } else if (tridionDocument?.translatedUrl) {
          htmlUrl = tridionDocument?.translatedUrl;
        } else {
          htmlUrl = getTridionHtmlUrl(documentId);
        }

        try {
          const response = await fetch(htmlUrl, {
            credentials: 'include',
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.text();
          setTridionDocument({
            ...tridionDocument,
            html: data,
          });
          dispatch(setIsTridion({ isTridion: true }));
        } catch (fetchError) {
          console.log(fetchError);
        }
      }
    };

    if (isTridionDoc) {
      fetchData();
    }
    //eslint-disable-next-line
  }, [tridionDocument?.translatedUrl, documentId, isTridionDoc, fetchTridionDocument]);

  const BuildTableOfContentsArray = () => {
    const h1Elements = document.querySelectorAll('h1');
    const h1Objects = [];

    h1Elements.forEach(h1 => {
      const h1Object = {
        id: h1.id,
        text: h1.textContent,
      };
      h1Objects.push(h1Object);
    });

    return h1Objects;
  };

  const [TableOfContentsArray, setTableOfContentsArray] = useState([]);

  useEffect(() => {
    if (tridionDocument?.html) {
      setTableOfContentsArray(BuildTableOfContentsArray());
    }
  }, [tridionDocument?.html]);

  const documentTitle = TableOfContentsArray?.[0]?.text;

  // This gets rid of the html tag that causes nesting errors
  const extractBodyContent = htmlString => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const body = doc.querySelector('body');
    return body ? body.innerHTML : '';
  };

  const bodyContent = extractBodyContent(tridionDocument?.html);

  // Update image sources to hit the xp api
  useEffect(() => {
    if (isTridion && tridionDocument?.html) {
      const baseUrl =
        process?.env?.NODE_ENV === Constants.APP_ENVIRONMENTS.DEV
          ? Constants.XP_API_IMAGE_BASE_URL.DEV
          : process?.env?.NODE_ENV === Constants.APP_ENVIRONMENTS.TEST
          ? Constants.XP_API_IMAGE_BASE_URL.TEST
          : Constants.XP_API_IMAGE_BASE_URL.PROD;
      const images = document.querySelectorAll('img');
      images.forEach(image => {
        // We are removing anything that comes before "binary" which is the folder images are stored in
        const updatedImageSource = image.src.match(/.*\/(binary\/.*)/)?.[1];
        if (updatedImageSource !== undefined) {
          //eslint-disable-next-line
          image.src = `${baseUrl}/${updatedImageSource}`
        }
      });
    }
  }, [tridionDocument, isTridion]);

  // Printing a Tridion document
  useEffect(() => {
    if (isPrinting) {
      window.print();
    }
    dispatch(setIsPrinting({ isPrinting: false }));
  }, [dispatch, isPrinting]);

  // Translating a Tridion document
  useEffect(() => {
    if (isTranslating) {
      setTridionDocument({
        ...tridionDocument,
        translatedUrl: tridionDocument?.translatedUrl ? '' : translatedHtml,
        useTranslatedUrl:
          isTridionDocumentFromSearch && !tridionDocument?.translatedUrl
            ? true
            : false,
      });
    }
    dispatch(setIsTranslating({ isTranslating: false }));
  }, [
    dispatch,
    isTranslating,
    isTridionDocumentFromSearch,
    translatedHtml,
    tridionDocument,
  ]);

  // this XHR request pway api calls xyleme  /documents endpoint end sends back this data
  // which is used to get the url used in the iframe src to the document -  index.html running on
  // xylemes server. The XHR response also includes the auth cookie xyleme uses to allow access
  // to the document. Without this cookie the iframe content will not be loaded. React Toolkit Query
  // is a wrapper around the DOM fetch API and uses "credentials": include as a fetch arg to save and
  // add this cookie to headers which must be present in the iframe src link for content_url
  const contentUrlMap = {
    [Constants.EXPANDED_FILE_FORMAT_TYPES.GAME]: () => gameUrl,
    [Constants.EXPANDED_FILE_FORMAT_TYPES.JOB_AID]: (
      version_id,
      file_id,
      document_name,
    ) =>
      `${
        Constants.PATHWAY_API_CONTENT_URL
      }/${version_id}/${file_id}/${encodeURIComponent(document_name)}`,
    [Constants.EXPANDED_FILE_FORMAT_TYPES.SCORM]: (version_id, file_id) =>
      `${Constants.PATHWAY_API_CONTENT_URL}/${version_id}/${file_id}/scorm-index.html`,
  };

  const getUrl = () => {
    // Early returns for known cases
    if (documentUrlFromSearch?.base) {
      return documentUrlFromSearch?.base;
    }
    if (userRefreshesPageAfterComingFromSearch) {
      return docUrlAfterRefresh;
    }
    if (!isTridion && getXylemeHtmlUrl(documentId)) {
      return getXylemeHtmlUrl(documentId);
    }
    if (isCompliance) {
      return complianceUrl.url;
    }

    // Handle Tridion case directly (avoids 500 error with undefined values below)
    if (isTridion) {
      return;
    }

    // Use a default URL generator if none is found
    const urlGenerator = contentUrlMap[documentType] || defaultUrlGenerator;
    return urlGenerator(versionId, fileId);
  };

  const defaultUrlGenerator = (version_id, file_id) => {
    if (!version_id || !file_id) {
      return;
    }
    return `${Constants.PATHWAY_API_CONTENT_URL}/${version_id}/${file_id}/index.html`;
  };

  const handleSuccessVideoLoad = () => {};

  //Error Handling
  if (isApiError(complianceUrlError) && isCompliance) {
    if (error.status === 404) {
      Constants.BUGSNAG_ENABLED &&
        Bugsnag.notify(formatBugsnagErrorMessage(complianceUrlError));
      return <PageNotFound404 />;
    } else {
      Constants.BUGSNAG_ENABLED &&
        Bugsnag.notify(formatBugsnagErrorMessage(complianceUrlError));
      return <GenericError />;
    }
  }
  if (isApiError(error) && !(error.status === 403 && !isCompliance)) {
    // 403 && !isCompliance will show the user unAuthorized modal
    if (error.status === 500) {
      // 500s will show the error loading resource modal, still want to log
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    } else if (error.status === 404) {
      Constants.BUGSNAG_ENABLED &&
        Bugsnag.notify(formatBugsnagErrorMessage(error));
      return <PageNotFound404 />;
    } else {
      Constants.BUGSNAG_ENABLED &&
        Bugsnag.notify(formatBugsnagErrorMessage(error));
      return <GenericError />;
    }
  }
  if (errorMsg === Constants.API_ERROR_CODES.RESOURCE_NOT_FOUND) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(
        formatBugsnagErrorMessage({ ...errorMsg, documentId: id }),
      );
    return <PageNotFound404 />;
  }

  //eslint-disable-next-line
  const content_iframe = () => {
    if (
      (!versionId && !isCompliance && !xpApiFeatureFlag) ||
      (!fileId && !isCompliance && !xpApiFeatureFlag)
    ) {
      return null;
    }
    if (
      documentType?.toLowerCase() ===
      Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE
    ) {
      const videoReference = getNameFromLanguage(document_detail?.references);
      if (!videoReference) {
        return <GenericError />;
      }
      const brightcoveSrcUrl = new URL(videoReference.src);
      const brightcoveAccountId = brightcoveSrcUrl.pathname.match(/\d+/);
      const brightcoveVideoId = brightcoveSrcUrl.searchParams.get('videoId');
      return (
        <StyledVideoContainer>
          <StyledVideoTitle $isDesktop={isDesktopWidth}>
            {documentName}
          </StyledVideoTitle>
          <StyledVideoCard $isDesktop={isDesktopWidth} elevation={1}>
            <CardContent>
              <ReactPlayerLoader
                accountId={brightcoveAccountId}
                onSuccess={handleSuccessVideoLoad}
                options={{
                  aspectRatio: '16:9',
                  autoplay: false,
                  controls: false,
                  fluid: true,
                  loop: false,
                  muted: false,
                  playsInline: true,
                  responsive: true,
                }}
                videoId={brightcoveVideoId}
              />
            </CardContent>
          </StyledVideoCard>
        </StyledVideoContainer>
      );
    }
    return (
      <StyledIFrame
        $calculatedHeight={calculateIframeHeight()}
        allow="fullscreen"
        className="content-iframe"
        onLoad={onIframeLoad}
        src={getUrl()}
      />
    );
  };

  const storedCategoryName = localStorage.getItem('tridionCategoryName');
  const storedSubcategoryName = localStorage.getItem('tridionSubcategoryName');

  const goBack = () => {
    clearLocalStorage();
    history.goBack();
  };

  const breadcrumbs = [
    {
      label: t('TrainingPlans.tabExplore'),
      to: '/',
    },
    {
      label: storedCategoryName,
      onClick: () => goBack(),
    },
    {
      label: storedSubcategoryName,
      onClick: () => goBack(),
    },
    // Design wants the chevron right icon to show at the end without a label
    {
      label: '',
    },
  ];

  const breadcrumbsFromSearch = [
    {
      label: t('Generic.search'),
      onClick: () => goBack(),
    },
    // Design wants the chevron right icon to show at the end without a label
    {
      label: '',
    },
  ];

  return (
    <DocumentContainer
      $isPrinting={isPrinting}
      $isTridion={isTridion}
      className="p-0 h-100"
      fluid
    >
      <LoadingOverlay isOpen={isFetching} />
      <DocumentHead pageTitle={documentName} />
      <StyledContentWrapper $isPrinting={isPrinting} $isTridion={isTridion}>
        {shouldUseIframe ? (
          content_iframe()
        ) : (
          <PageWrapper $isMobile={isSmAndDown}>
            {isSmAndDown ? (
              <MobileWrapper>
                {!isPrinting && (
                  <>
                    <DocumentTitle
                      icon={getIcon(documentId)}
                      isMobile={true}
                      title={documentTitle}
                    />
                    <MobileImageWrapper>
                      <DocumentImageOuterCircle>
                        <DocumentImage
                          alt="document icon"
                          src={`${
                            Constants.PATHWAY_CDN_IMG.PROCEDURES
                          }${getIcon(documentId)}.svg`}
                        />
                      </DocumentImageOuterCircle>
                    </MobileImageWrapper>
                  </>
                )}
                <div className={styles.tridionDocument}>
                  <div style={{ padding: '1em' }}>
                    <TableOfContents
                      isMobile={true}
                      tocItems={TableOfContentsArray}
                    />
                  </div>
                  <MobileContent>{parse(bodyContent)}</MobileContent>
                </div>
              </MobileWrapper>
            ) : (
              <DesktopWrapper>
                <DocumentWrapper $isPrinting={isPrinting}>
                  {!isPrinting && (
                    <div>
                      <DocumentTitle
                        breadcrumbs={
                          isTridionDocumentFromSearch
                            ? breadcrumbsFromSearch
                            : breadcrumbs
                        }
                        icon={getIcon(documentId)}
                        title={documentTitle}
                      />
                      <DesktopImageWrapper>
                        <DocumentImageOuterCircle>
                          <DocumentImage
                            alt="document icon"
                            src={`${
                              Constants.PATHWAY_CDN_IMG.PROCEDURES
                            }${getIcon(documentId)}.svg`}
                          />
                        </DocumentImageOuterCircle>
                      </DesktopImageWrapper>
                    </div>
                  )}
                  {isPrinting && (
                    <TableOfContents tocItems={TableOfContentsArray} />
                  )}
                  <div
                    className={styles.tridionDocument}
                    style={{ padding: '0 1em 1em' }}
                  >
                    {parse(bodyContent)}
                  </div>
                </DocumentWrapper>
                {!isPrinting && (
                  <TableOfContents tocItems={TableOfContentsArray} />
                )}
              </DesktopWrapper>
            )}
          </PageWrapper>
        )}

        <ConfirmationModal
          bodyText={t('LoadingResourceError.errorParagraph')}
          headerText={t('GenericError.error')}
          isError={true}
          isOpen={
            (error && error.status === 500) ||
            errorMsg === Constants.API_ERROR_CODES.ERROR_LOADING_RESOURCE
          }
          onClose={() => window.location.reload()}
          primaryButtonHandler={() => window.location.reload()}
          primaryButtonText={t('Button.reloadThePage')}
        />

        <ConfirmationModal
          bodyText={t('LoadingResourceError.unauthorizedParagraph')}
          headerText={t('GenericError.unauthorized')}
          isOpen={unauthorizedError}
          onClose={() => (window.location.href = '/')}
          primaryButtonHandler={() => (window.location.href = '/')}
          primaryButtonText={t('Button.returnToHomepage')}
        />
      </StyledContentWrapper>
    </DocumentContainer>
  );
};

const DocumentContainer = styled(Container)`
  width: 100%;
  max-width: 840px;
  /* We need this so that the content is centered when printing a Tridion document */
  margin-left: ${({ $isPrinting, $isTridion }) =>
    $isPrinting && $isTridion && '-5em'};
`;

const StyledVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledVideoCard = styled(Card)`
  animation: fadeIn linear 0.3s;
  min-width: ${({ $isDesktop }) => ($isDesktop ? '840px !important' : null)};
  margin: ${({ $isDesktop }) => ($isDesktop ? '0 auto 2em auto' : '0.25em 0')};
`;

const StyledVideoTitle = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray6};
  font-size: 32px;
  font-weight: 700;
  padding: 10px 0 24px 0;
  line-height: 40px;
  margin-top: 20px;
  margin-left: ${({ $isDesktop }) => ($isDesktop ? null : '1em')};
  text-align: ${({ $isDesktop }) => ($isDesktop ? 'center' : 'left')};
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  align-items: ${({ $isPrinting, $isTridion }) =>
    $isTridion && !$isPrinting && 'center'};
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ $isMobile }) => !$isMobile && '1em 0 0 1em'};
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileContent = styled.div`
  background: white;
  padding: 1em;
`;

const DesktopWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const DocumentWrapper = styled.div`
  background: white;
  box-shadow: ${({ $isPrinting, theme }) =>
    !$isPrinting && theme.boxShadow.elevation4};
  width: 100%;
  border-top: ${({ $isPrinting, theme }) =>
    !$isPrinting && `5px solid ${theme.primaryPalette.navyBlue}`};
  position: relative;
`;

const StyledIFrame = styled.iframe`
  border: 0;
  width: 100%;
  height: ${({ $calculatedHeight }) => $calculatedHeight};
`;

const MobileImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  transform: translateY(-50%);
`;

const DesktopImageWrapper = styled.div`
  margin-left: 1em;
  transform: translateY(-50%);
`;

const DocumentImageOuterCircle = styled.div`
  background-color: white;
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.boxShadow.elevation8};
  height: 72px;
  position: relative;
  width: 72px;
`;

const DocumentImage = styled.img`
  background-color: white;
  border: ${({ theme }) => `3px solid ${theme.primaryPalette.navyBlue}`};
  border-radius: 50%;
  height: 66px;
  padding: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 66px;
`;

export default memo(Document);
// export default Document;
